import * as React from "react";
import Layout from "../components/layout";
import Seo from '../components/seo/seoall'
import { graphql } from "gatsby"


const IndexPage = ({data}) => {



  return (
    <div>
      <Layout>
      <Seo description={data.allDescriptionsJson.nodes[0].description} slug={data.allDescriptionsJson.nodes[0].page_location} category={data.allDescriptionsJson.nodes[0].page_location} />
        <div className="container-fluid p-0">
            <div className="row justify-content-center sec-color">
                <h1 className="text-center py-5">
                    
                    Disclaimer
                  
                  
                  </h1>
            </div>
            <div className="row justify-content-center single-post m-auto my-5">
                <div className="col-12 p-0">

                Please contact us by email if you require any additional information or have any queries about our site's disclaimer.<br/> <br/>All of the information on this website answeringeverything.com is provided in good faith and solely for the purpose of providing general information. answeringeverything.com makes no representations or warranties about the completeness, accuracy, or reliability of this material. Any action you take as a result of the material on this website (answeringeverything.com) is solely at your own risk. answeringeverything.com is not responsible for any losses or damages incurred as a result of using our website.<br/><br/>You can visit other websites from our website by clicking on hyperlinks to such external sites. We make every effort to give only high-quality links to useful and ethical websites, but we have no control over their content or nature. These connections to other websites do not mean that all of the content on other sites is recommended. Owners and content on the site may change without warning, and this may happen before we have a chance to delete a link that has gone 'bad.'<br/> <br/>Please be aware that when you leave our website, other sites' privacy policies and conditions may differ from ours, and we have no control over them. Please review these sites' privacy policies as well as their "Terms of Service" before using them.<br/>



                </div>
            </div>
        


        </div>
      </Layout>
    </div>
  );
};

export default IndexPage;

export   const query = graphql`
query {
  allDescriptionsJson(filter: {page_location: {eq: "disclaimer"}}) {
    nodes {
      page_location
      description
    }
  }
}


`;
